import React, { useState, useRef, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faMinus } from "@fortawesome/pro-solid-svg-icons"

const BUSINESS_PROFILE = "businessProfile"
const LEADS = "leads"
const CLOSE_RATE = "closeRate"
const PROJECT_SIZE = "projectSize"
const GROWTH_SUMMARY = "growthSummary"
const RETURN_ON_INVESTMENT = "returnOnInvestment"
const PROJECT_SIZE_DROPDOWN = "projectSizeDropdown"
const CLOSE_RATE_DROPDOWN = "closeRateDropdown"
const BEST_PRACTICES_DROPDOWN = "bestPracticesDropdown"

const computedFields = {
  businessProfileTotalResidentialJobsPerYear: "",
  leadsCurrentLeadsPerYear: "",
  leadsNewLeadsPerYear: "",
  leadsJobsIncreasedPerYear: "",
  leadsPotentialRevenueLossGain: "",
  closeRateNumJobsIncreased: "",
  closeRatePotentialRevenueLossGain: "",
  closeRateWithExistingCloseRate: "",
  closeRateWithNewLeadsPerYear: "",
  closeRateWithNewCloseRate: "",
  closeRateWithPotentialRevenueLossGain: "",
  closeRateWithNumJobsIncreasedPerYear: "",
  projectSizeWithoutCurrentAverageProjectSize: "",
  projectSizeWithoutPotentialRevenueLossGain: "",
  projectSizeWithCurrentAverageProjectSize: "",
  projectSizeWithPercentGrowth: "",
  projectSizeWithoutNewAverageFinancedTicket: "",
  projectSizeWithNewAverageFinancedTicket: "",
  projectSizeWithPotentialRevenueLossGain: "",
  growthSummaryNewLeads: "",
  growthSummaryNewCloseRate: "",
  growthSummaryNewProjectSize: "",
  growthSummaryCurrentAnnualResidentialSales: "",
  growthSummaryNewAnnualSalesEstimate: "",
  growthSummaryTotalRevenueLossGain: "",
  closeRateExistingCloseRate: "",
  projectSizeWithPercentTotalProjectsFinanced: "",
  returnOnInvestmentAverageContractorFee: "",
  returnOnInvestmentNewAnnualSalesEstimateFees: "",
  returnOnInvestmentTotalFees: "",
  returnOnInvestmentTotalRevenueAfterFees: "",
}

const inputFields = {
  businessProfileAnnualResidentialSalesVolume: "",
  businessProfileAverageProjectSize: "",
  leadsPercentIncrease: "",
  businessProfileCurrentCloseRate: "",
  closeRateNewCloseRate: "",
  projectSizeWithoutPercentGrowth: "",
  projectSizeWithoutPercentTotalProjectsFinanced: "",
  returnOnInvestmentFee1: "",
  returnOnInvestmentFee2: "",
  returnOnInvestmentFee3: "",
}

const BusinessGrowthEstimator = () => {
  const [activeTab, setActiveTab] = useState(BUSINESS_PROFILE)
  const [activeDropdown, setActiveDropdown] = useState("")
  const [figures, setFigures] = useState({
    ...inputFields,
    ...computedFields,
  })

  const [fieldsStatus, setFieldsStatus] = useState({})
  const [updatedStatusColors, setUpdatedStatusColors] = useState(false)

  const tabsRef = useRef(null)
  const formRef = useRef(null)

  const formatWithCommas = input => {
    let formatedInput = input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    // return formatedInput.startsWith("0") && formatedInput !== "0"
    //   ? formatedInput.replace(/^0+/, "")
    //   : formatedInput
    return formatedInput
  }

  var getNumber = input => {
    return input !== "" && typeof input === "string"
      ? Number(input.replaceAll("%", "").replaceAll(",", ""))
      : input
  }

  const getMathValues = values => {
    return Object.entries(values).reduce((acc, [key, value]) => {
      acc[key] = getNumber(value)
      return acc
    }, {})
  }

  const getPresentationValues = values => {
    const percentFields = [
      "businessProfileCurrentCloseRate",
      "leadsPercentIncrease",
      "closeRateExistingCloseRate",
      "closeRateNewCloseRate",
      "closeRateWithExistingCloseRate",
      "closeRateWithNewCloseRate",
      "projectSizeWithoutPercentGrowth",
      "projectSizeWithoutPercentTotalProjectsFinanced",
      "projectSizeWithPercentGrowth",
      "projectSizeWithPercentTotalProjectsFinanced",
      "growthSummaryNewCloseRate",
      "returnOnInvestmentFee1",
      "returnOnInvestmentFee2",
      "returnOnInvestmentFee3",
      "returnOnInvestmentAverageContractorFee",
    ]

    return Object.entries(values).reduce((acc, [key, value]) => {
      if (percentFields.includes(key) && value !== "") {
        acc[key] = formatWithCommas(value).replaceAll("%", "") + "%"
      } else {
        acc[key] = formatWithCommas(value)
      }

      return acc
    }, {})
  }

  const getAverage = (...args) => {
    const values = args.filter(arg => arg !== "" && arg >= 0)
    const itemsCount = values.length
    if (!itemsCount) return 0
    const sum = values.reduce((acc, curr) => acc + curr, 0)
    let average = sum / itemsCount
    return average.toFixed(2)
  }

  const validateInputs = (...inputs) => {
    return inputs.every(input => getNumber(input) > 0)
  }

  const handleInputChange = event => {
    const { name, value } = event.target
    let sanitizedValue = value
    if (
      sanitizedValue.length === 2 &&
      sanitizedValue.charAt(0) === "0" &&
      sanitizedValue.charAt(1) !== "."
    ) {
      sanitizedValue = sanitizedValue.substring(1)
    }
    sanitizedValue = sanitizedValue.replace(/[^0-9.]/g, "")
    setFigures(curr => estimateGrowth({ ...curr, [name]: sanitizedValue }))
    setUpdatedStatusColors(false)
  }

  const addPercentSign = event => {
    const { name, value, dataset } = event.target
    if (dataset?.percentage && value?.trim() !== "" && !value?.includes("%")) {
      setFigures(curr => ({ ...curr, [name]: value + "%" }))
    }
  }

  const removePercentSign = event => {
    const { name, value, dataset } = event.target
    if (dataset?.percentage) {
      setFigures(curr => ({ ...curr, [name]: value.replaceAll("%", "") }))
    }
  }

  const handleTabChange = event => {
    const { id } = event.target
    if (id) {
      setActiveTab(id)
    }
  }

  const getActiveTabClass = tab => {
    return activeTab === tab ? "bg-regions-life-green" : ""
  }

  const isActiveDropdown = dropdown => activeDropdown === dropdown

  const toggleDropdown = event => {
    const { id } = event.target
    if (id === activeDropdown) {
      setActiveDropdown("")
    } else {
      setActiveDropdown(id)
    }
  }

  const getColorClassTotal = field => {
    return fieldsStatus[field] === "negative"
      ? "text-red"
      : fieldsStatus[field] === "positive"
      ? "text-green"
      : ""
  }

  const getTransparencyClass = () => {
    return figures.closeRateWithNewCloseRate === "" ? "text-transparent" : ""
  }

  const estimateGrowth = data => {
    const values = getMathValues(data)

    // Validate required fields that must be filled out
    const validated = validateInputs(
      values.businessProfileAnnualResidentialSalesVolume,
      values.businessProfileAverageProjectSize,
      values.businessProfileCurrentCloseRate
    )

    if (!validated) {
      return getPresentationValues({ ...data, ...computedFields })
    }

    // Helper variables
    const businessProfileCurrentCloseRatePercentage =
      values.businessProfileCurrentCloseRate / 100

    const closeRateNewCloseRatePercentage = values.closeRateNewCloseRate / 100

    const projectSizeWithoutPercentTotalProjectsFinancedPercentage =
      values.projectSizeWithoutPercentTotalProjectsFinanced / 100

    // Calculations
    const businessProfileTotalResidentialJobsPerYear = Math.round(
      values.businessProfileAnnualResidentialSalesVolume /
        values.businessProfileAverageProjectSize
    )

    const leadsCurrentLeadsPerYear = Math.round(
      businessProfileTotalResidentialJobsPerYear /
        businessProfileCurrentCloseRatePercentage
    )

    const leadsNewLeadsPerYear = Math.round(
      (values.leadsPercentIncrease / 100 + 1) * leadsCurrentLeadsPerYear
    )

    const leadsJobsIncreasedPerYear = Math.round(
      leadsNewLeadsPerYear * businessProfileCurrentCloseRatePercentage -
        businessProfileTotalResidentialJobsPerYear
    )

    const leadsPotentialRevenueLossGain = Math.round(
      leadsJobsIncreasedPerYear * values.businessProfileAverageProjectSize
    )

    const closeRateNumJobsIncreased = Math.round(
      leadsCurrentLeadsPerYear * closeRateNewCloseRatePercentage -
        businessProfileTotalResidentialJobsPerYear
    )

    const closeRatePotentialRevenueLossGain = Math.round(
      closeRateNumJobsIncreased * values.businessProfileAverageProjectSize
    )

    const closeRateWithNumJobsIncreasedPerYear = Math.round(
      leadsNewLeadsPerYear * closeRateNewCloseRatePercentage -
        businessProfileTotalResidentialJobsPerYear
    )

    const closeRateWithPotentialRevenueLossGain = Math.round(
      closeRateWithNumJobsIncreasedPerYear *
        values.businessProfileAverageProjectSize
    )

    const projectSizeWithoutNewAverageFinancedTicket = Math.round(
      (1 + values.projectSizeWithoutPercentGrowth / 100) *
        values.businessProfileAverageProjectSize
    )

    const projectSizeWithoutPotentialRevenueLossGain = Math.round(
      (businessProfileTotalResidentialJobsPerYear *
        projectSizeWithoutNewAverageFinancedTicket -
        businessProfileTotalResidentialJobsPerYear *
          values.businessProfileAverageProjectSize) *
        projectSizeWithoutPercentTotalProjectsFinancedPercentage
    )

    const projectSizeWithPotentialRevenueLossGain = Math.round(
      ((businessProfileTotalResidentialJobsPerYear +
        closeRateWithNumJobsIncreasedPerYear) *
        projectSizeWithoutNewAverageFinancedTicket -
        (businessProfileTotalResidentialJobsPerYear +
          closeRateWithNumJobsIncreasedPerYear) *
          values.businessProfileAverageProjectSize) *
        projectSizeWithoutPercentTotalProjectsFinancedPercentage
    )

    const growthSummaryNewAnnualSalesEstimate = Math.round(
      leadsNewLeadsPerYear *
        closeRateNewCloseRatePercentage *
        projectSizeWithoutNewAverageFinancedTicket *
        projectSizeWithoutPercentTotalProjectsFinancedPercentage +
        leadsNewLeadsPerYear *
          closeRateNewCloseRatePercentage *
          values.businessProfileAverageProjectSize *
          (1 - projectSizeWithoutPercentTotalProjectsFinancedPercentage)
    )

    const growthSummaryTotalRevenueLossGain = Math.round(
      growthSummaryNewAnnualSalesEstimate -
        values.businessProfileAnnualResidentialSalesVolume
    )

    const returnOnInvestmentAverageContractorFee = getAverage(
      values.returnOnInvestmentFee1,
      values.returnOnInvestmentFee2,
      values.returnOnInvestmentFee3
    )

    const returnOnInvestmentNewAnnualSalesEstimateFees = Math.round(
      growthSummaryNewAnnualSalesEstimate -
        (growthSummaryNewAnnualSalesEstimate *
          projectSizeWithoutPercentTotalProjectsFinancedPercentage *
          returnOnInvestmentAverageContractorFee) /
          100
    )

    const returnOnInvestmentTotalFees = Math.round(
      growthSummaryNewAnnualSalesEstimate -
        returnOnInvestmentNewAnnualSalesEstimateFees
    )

    const returnOnInvestmentTotalRevenueAfterFees = Math.round(
      returnOnInvestmentNewAnnualSalesEstimateFees -
        values.businessProfileAnnualResidentialSalesVolume
    )

    const newValues = {
      ...data,
      businessProfileTotalResidentialJobsPerYear: businessProfileTotalResidentialJobsPerYear,
      leadsCurrentLeadsPerYear: leadsCurrentLeadsPerYear,
      leadsNewLeadsPerYear: leadsNewLeadsPerYear,
      leadsJobsIncreasedPerYear: leadsJobsIncreasedPerYear,
      leadsPotentialRevenueLossGain: leadsPotentialRevenueLossGain,
      closeRateExistingCloseRate: values.businessProfileCurrentCloseRate,
      closeRateNumJobsIncreased: closeRateNumJobsIncreased,
      closeRatePotentialRevenueLossGain: closeRatePotentialRevenueLossGain,
      closeRateWithExistingCloseRate: values.businessProfileCurrentCloseRate,
      closeRateWithNewLeadsPerYear: leadsNewLeadsPerYear,
      closeRateWithNewCloseRate: values.closeRateNewCloseRate,
      closeRateWithNumJobsIncreasedPerYear: closeRateWithNumJobsIncreasedPerYear,
      closeRateWithPotentialRevenueLossGain: closeRateWithPotentialRevenueLossGain,
      projectSizeWithoutCurrentAverageProjectSize:
        values.businessProfileAverageProjectSize,
      projectSizeWithoutNewAverageFinancedTicket: projectSizeWithoutNewAverageFinancedTicket,
      projectSizeWithoutPotentialRevenueLossGain: projectSizeWithoutPotentialRevenueLossGain,
      projectSizeWithCurrentAverageProjectSize:
        values.businessProfileAverageProjectSize,
      projectSizeWithPercentGrowth: values.projectSizeWithoutPercentGrowth,
      projectSizeWithPercentTotalProjectsFinanced:
        values.projectSizeWithoutPercentTotalProjectsFinanced,
      projectSizeWithNewAverageFinancedTicket: projectSizeWithoutNewAverageFinancedTicket,
      projectSizeWithPotentialRevenueLossGain: projectSizeWithPotentialRevenueLossGain,
      growthSummaryNewLeads: leadsNewLeadsPerYear,
      growthSummaryNewCloseRate: values.closeRateNewCloseRate,
      growthSummaryNewProjectSize: projectSizeWithoutNewAverageFinancedTicket,
      growthSummaryCurrentAnnualResidentialSales:
        values.businessProfileAnnualResidentialSalesVolume,
      growthSummaryNewAnnualSalesEstimate: growthSummaryNewAnnualSalesEstimate,
      growthSummaryTotalRevenueLossGain: growthSummaryTotalRevenueLossGain,
      returnOnInvestmentAverageContractorFee: returnOnInvestmentAverageContractorFee,
      returnOnInvestmentTotalFees: returnOnInvestmentTotalFees,
      returnOnInvestmentNewAnnualSalesEstimateFees: returnOnInvestmentNewAnnualSalesEstimateFees,
      returnOnInvestmentTotalRevenueAfterFees: returnOnInvestmentTotalRevenueAfterFees,
    }

    return getPresentationValues(newValues)
  }

  // Remove % sign from percent input fields when focused
  useEffect(() => {
    const focusedInput = formRef.current.querySelector("input:focus")
    if (focusedInput) {
      const { name } = focusedInput
      setFigures(curr => ({
        ...curr,
        [name]: focusedInput.value.replaceAll("%", ""),
      }))
    }
  }, [
    figures.businessProfileCurrentCloseRate,
    figures.leadsPercentIncrease,
    figures.closeRateNewCloseRate,
    figures.projectSizeWithoutPercentGrowth,
    figures.projectSizeWithoutPercentTotalProjectsFinanced,
    figures.returnOnInvestmentFee1,
    figures.returnOnInvestmentFee2,
    figures.returnOnInvestmentFee3,
  ])

  // Remove minus sign from negative numbers and apply corresponding colors
  useEffect(() => {
    if (updatedStatusColors) return
    let updatedFieldsStatus = {}
    const totalsKeys = [
      "returnOnInvestmentTotalRevenueAfterFees",
      "growthSummaryTotalRevenueLossGain",
      "leadsPotentialRevenueLossGain",
      "closeRatePotentialRevenueLossGain",
      "closeRateWithPotentialRevenueLossGain",
      "projectSizeWithoutPotentialRevenueLossGain",
      "projectSizeWithPotentialRevenueLossGain",
    ]

    totalsKeys.forEach(field => {
      const value = figures[field]
      if (!value || value === "0") {
        updatedFieldsStatus[field] = "neutral"
      } else if (value.startsWith("-")) {
        updatedFieldsStatus[field] = "negative"
      } else {
        updatedFieldsStatus[field] = "positive"
      }
    })

    if (!Object.keys(updatedFieldsStatus).length) return

    setFieldsStatus(curr => ({ ...curr, ...updatedFieldsStatus }))
    setUpdatedStatusColors(true)

    setFigures(curr => {
      const targetFields = Object.keys(updatedFieldsStatus)
      const updatedFigures = { ...curr }
      targetFields.forEach(name => {
        updatedFigures[name] = updatedFigures[name].replace("-", "")
      })

      return updatedFigures
    })
  }, [
    figures.returnOnInvestmentTotalRevenueAfterFees,
    figures.growthSummaryTotalRevenueLossGain,
    figures.leadsPotentialRevenueLossGain,
    figures.closeRatePotentialRevenueLossGain,
    figures.closeRateWithPotentialRevenueLossGain,
    figures.projectSizeWithoutPotentialRevenueLossGain,
    figures.projectSizeWithPotentialRevenueLossGain,
  ])

  const ToggleIcon = ({ dropdown }) => {
    return isActiveDropdown(dropdown) ? (
      <FontAwesomeIcon icon={faMinus} className="rotate-clock-wise mr-2" />
    ) : (
      <FontAwesomeIcon icon={faPlus} className="rotate-anti-clock-wise mr-2" />
    )
  }

  return (
    <div className="container-fluid growth-estimator bg-light-gray pb-2">
      <div className="growth-estimator-tabs pt-4">
        <ul className="row" ref={tabsRef} onClick={handleTabChange}>
          <li className="growth-estimator-tab col-lg-2 mb-2">
            <button
              className={`growth-estimator-tab-btn d-block w-100 text-center border-0 rounded p-2 font-weight-bold ${getActiveTabClass(
                BUSINESS_PROFILE
              )}`}
              id={BUSINESS_PROFILE}
            >
              Business Profile
            </button>
          </li>
          <li className="growth-estimator-tab col-lg-2 mb-2">
            <button
              className={`growth-estimator-tab-btn d-block w-100 text-center border-0 rounded p-2 font-weight-bold ${getActiveTabClass(
                LEADS
              )}`}
              id={LEADS}
            >
              Leads
            </button>
          </li>
          <li className="growth-estimator-tab col-lg-2 mb-2">
            <button
              className={`growth-estimator-tab-btn d-block w-100 text-center border-0 rounded p-2 font-weight-bold ${getActiveTabClass(
                CLOSE_RATE
              )}`}
              id={CLOSE_RATE}
            >
              Close Rate
            </button>
          </li>
          <li className="growth-estimator-tab col-lg-2 mb-2">
            <button
              className={`growth-estimator-tab-btn d-block w-100 text-center border-0 rounded p-2 font-weight-bold ${getActiveTabClass(
                PROJECT_SIZE
              )}`}
              id={PROJECT_SIZE}
            >
              Project Size
            </button>
          </li>
          <li className="growth-estimator-tab col-lg-2 mb-2">
            <button
              className={`growth-estimator-tab-btn d-block w-100 text-center border-0 rounded p-2 font-weight-bold ${getActiveTabClass(
                GROWTH_SUMMARY
              )}`}
              id={GROWTH_SUMMARY}
            >
              Growth Summary
            </button>
          </li>
          <li className="growth-estimator-tab col-lg-2 mb-2">
            <button
              className={`growth-estimator-tab-btn d-block w-100 text-center border-0 rounded p-2 font-weight-bold ${getActiveTabClass(
                RETURN_ON_INVESTMENT
              )}`}
              id={RETURN_ON_INVESTMENT}
            >
              Return on Investment
            </button>
          </li>
        </ul>
      </div>

      <div className="growth-estimator-content bg-white my-2 p-4 p-md-5 rounded">
        <div className="growth-estimator-tabs-content">
          <form ref={formRef}>
            {activeTab === BUSINESS_PROFILE && (
              <div className="growth-estimator-tab-content">
                <fieldset className="border rounded py-4 px-5">
                  <div className="row mb-2 align-items-center">
                    <label
                      className="col-md-4 pl-0 mb-0"
                      htmlFor="businessProfileAnnualResidentialSalesVolume"
                    >
                      Annual Residential Sales Volume
                    </label>
                    <div className="col-md-8 pl-0 pr-0 currency-input">
                      <input
                        type="text"
                        value={
                          figures.businessProfileAnnualResidentialSalesVolume
                        }
                        id="businessProfileAnnualResidentialSalesVolume"
                        name="businessProfileAnnualResidentialSalesVolume"
                        onChange={handleInputChange}
                        className="rounded p-2 pl-3 border d-block w-100"
                        inputMode="decimal"
                      />
                    </div>
                  </div>

                  <div className="row mb-2 align-items-center">
                    <label
                      className="col-md-4 pl-0 mb-0"
                      htmlFor="businessProfileAverageProjectSize"
                    >
                      Average Project Size
                    </label>
                    <div className="col-md-8 pl-0 pr-0 currency-input">
                      <input
                        type="text"
                        value={figures.businessProfileAverageProjectSize}
                        id="businessProfileAverageProjectSize"
                        name="businessProfileAverageProjectSize"
                        onChange={handleInputChange}
                        className="rounded p-2 pl-3 border d-block w-100"
                        inputMode="decimal"
                      />
                    </div>
                  </div>
                  <div className="row mb-2 align-items-center">
                    <label
                      className="col-md-4 pl-0 mb-0"
                      htmlFor="businessProfileCurrentCloseRate"
                    >
                      Current Close Rate
                    </label>
                    <div className="col-md-8 pl-0 pr-0">
                      <input
                        type="text"
                        value={figures.businessProfileCurrentCloseRate}
                        id="businessProfileCurrentCloseRate"
                        name="businessProfileCurrentCloseRate"
                        onChange={handleInputChange}
                        className="rounded p-2 border d-block w-100"
                        inputMode="decimal"
                        data-percentage="true"
                        onBlur={addPercentSign}
                        onFocus={removePercentSign}
                      />
                    </div>
                  </div>
                  <div className="row mb-0 align-items-center">
                    <label
                      className="col-md-4 pl-0 mb-0"
                      htmlFor="businessProfileTotalResidentialJobsPerYear"
                    >
                      Total Residential Jobs/Year
                    </label>
                    <div className="col-md-8 pl-0 pr-0">
                      <input
                        type="text"
                        value={
                          figures.businessProfileTotalResidentialJobsPerYear
                        }
                        id="businessProfileTotalResidentialJobsPerYear"
                        name="businessProfileTotalResidentialJobsPerYear"
                        disabled={true}
                        className="rounded p-2 border d-block w-100"
                      />
                    </div>
                  </div>
                </fieldset>
              </div>
            )}
            {activeTab === LEADS && (
              <div className="growth-estimator-tab-content">
                <h3 className="text-uppercase mb-2 text-md">Increased Leads</h3>
                <fieldset className="border rounded py-4 px-5">
                  <div className="row mb-2 align-items-center">
                    <label
                      className="col-md-4 pl-0 mb-0"
                      htmlFor="leadsCurrentLeadsPerYear"
                    >
                      Current Leads/Year
                    </label>
                    <div className="col-md-8 pl-0 pr-0">
                      <input
                        type="text"
                        value={figures.leadsCurrentLeadsPerYear}
                        id="leadsCurrentLeadsPerYear"
                        name="leadsCurrentLeadsPerYear"
                        disabled={true}
                        className="rounded p-2 border d-block w-100"
                      />
                    </div>
                  </div>
                  <div className="row mb-2 align-items-center">
                    <label
                      className="col-md-4 pl-0 mb-0 "
                      htmlFor="leadsPercentIncrease"
                    >
                      % Increase
                    </label>
                    <div className="col-md-8 pl-0 pr-0">
                      <input
                        type="text"
                        value={figures.leadsPercentIncrease}
                        id="leadsPercentIncrease"
                        name="leadsPercentIncrease"
                        onChange={handleInputChange}
                        className="rounded p-2 border d-block w-100"
                        data-percentage="true"
                        onBlur={addPercentSign}
                        onFocus={removePercentSign}
                        inputMode="decimal"
                      />
                    </div>
                  </div>
                  <div className="row mb-2 align-items-center">
                    <label
                      className="col-md-4 pl-0 mb-0"
                      htmlFor="leadsNewLeadsPerYear"
                    >
                      New Leads/Year
                    </label>
                    <div className="col-md-8 pl-0 pr-0">
                      <input
                        type="text"
                        value={figures.leadsNewLeadsPerYear}
                        id="leadsNewLeadsPerYear"
                        name="leadsNewLeadsPerYear"
                        disabled={true}
                        className="rounded p-2 border d-block w-100"
                      />
                    </div>
                  </div>

                  <div className="row mb-2 align-items-center">
                    <label
                      className="col-md-4 pl-0 mb-0"
                      htmlFor="leadsJobsIncreasedPerYear"
                    >
                      Jobs Increased/Year
                    </label>
                    <div className="col-md-8 pl-0 pr-0">
                      <input
                        type="text"
                        value={figures.leadsJobsIncreasedPerYear}
                        id="leadsJobsIncreasedPerYear"
                        name="leadsJobsIncreasedPerYear"
                        disabled={true}
                        className="rounded p-2 border d-block w-100"
                      />
                    </div>
                  </div>
                  <div className="row mb-0 align-items-center">
                    <label
                      className="col-md-4 pl-0 mb-0 font-weight-bold"
                      htmlFor="leadsPotentialRevenueLossGain"
                    >
                      Potential Revenue (<span className="text-red">Loss</span>/
                      <span className="text-green">Gain</span>)
                    </label>
                    <div
                      className={`col-md-8 pl-0 pr-0 currency-input ${getColorClassTotal(
                        "leadsPotentialRevenueLossGain"
                      )}`}
                    >
                      <input
                        type="text"
                        value={figures.leadsPotentialRevenueLossGain}
                        id="leadsPotentialRevenueLossGain"
                        name="leadsPotentialRevenueLossGain"
                        disabled={true}
                        className={`rounded p-2 pl-3 border d-block w-100 font-weight-bold ${getColorClassTotal(
                          "leadsPotentialRevenueLossGain"
                        )}`}
                      />
                    </div>
                  </div>
                </fieldset>
              </div>
            )}
            {activeTab === CLOSE_RATE && (
              <div className="growth-estimator-tab-content">
                <h3 className="text-uppercase mb-2 text-md">
                  Close Rate Impact
                </h3>
                <fieldset className="border rounded py-4 px-5">
                  <div className="row mb-2 align-items-center">
                    <label
                      className="col-md-4 pl-0 mb-0"
                      htmlFor="closeRateExistingCloseRate"
                    >
                      Existing Close Rate
                    </label>
                    <div className="col-md-8 pl-0 pr-0">
                      <input
                        type="text"
                        value={figures.closeRateExistingCloseRate}
                        id="closeRateExistingCloseRate"
                        name="closeRateExistingCloseRate"
                        disabled={true}
                        className="rounded p-2 border d-block w-100"
                      />
                    </div>
                  </div>
                  <div className="row mb-2 align-items-center">
                    <label
                      className="col-md-4 pl-0 mb-0"
                      htmlFor="closeRateNewCloseRate"
                    >
                      New Close Rate
                    </label>
                    <div className="col-md-8 pl-0 pr-0">
                      <input
                        type="text"
                        value={figures.closeRateNewCloseRate}
                        id="closeRateNewCloseRate"
                        name="closeRateNewCloseRate"
                        onChange={handleInputChange}
                        className="rounded p-2 border d-block w-100"
                        data-percentage="true"
                        onBlur={addPercentSign}
                        onFocus={removePercentSign}
                        inputMode="decimal"
                      />
                    </div>
                  </div>

                  <div className="row mb-2 align-items-center">
                    <label
                      className="col-md-4 pl-0 mb-0"
                      htmlFor="closeRateNumJobsIncreased"
                    >
                      # Jobs Increased
                    </label>
                    <div className="col-md-8 pl-0 pr-0">
                      <input
                        type="text"
                        value={figures.closeRateNumJobsIncreased}
                        id="closeRateNumJobsIncreased"
                        name="closeRateNumJobsIncreased"
                        disabled={true}
                        className="rounded p-2 border d-block w-100"
                      />
                    </div>
                  </div>
                  <div className="row mb-0 align-items-center">
                    <label
                      className="col-md-4 pl-0 mb-0 font-weight-bold"
                      htmlFor="closeRatePotentialRevenueLossGain"
                    >
                      Potential Revenue (<span className="text-red">Loss</span>/
                      <span className="text-green">Gain</span>)
                    </label>
                    <div
                      className={`col-md-8 pl-0 pr-0 currency-input ${getColorClassTotal(
                        "closeRatePotentialRevenueLossGain"
                      )} ${getTransparencyClass()}`}
                    >
                      <input
                        type="text"
                        value={figures.closeRatePotentialRevenueLossGain}
                        id="closeRatePotentialRevenueLossGain"
                        name="closeRatePotentialRevenueLossGain"
                        disabled={true}
                        className={`rounded p-2 pl-3 border d-block w-100 font-weight-bold ${getColorClassTotal(
                          "closeRatePotentialRevenueLossGain"
                        )} ${getTransparencyClass()}`}
                      />
                    </div>
                  </div>
                </fieldset>

                <div className="mt-5">
                  <button
                    type="button"
                    id={CLOSE_RATE_DROPDOWN}
                    onClick={toggleDropdown}
                    className="text-uppercase mb-2 text-md d-block border-0 bg-white"
                  >
                    <ToggleIcon dropdown={CLOSE_RATE_DROPDOWN} />
                    Increased Leads{" "}
                    <span className="text-green no-click">with</span> Close Rate
                    Increase
                  </button>
                  {isActiveDropdown(CLOSE_RATE_DROPDOWN) && (
                    <fieldset className="border rounded py-4 px-5 growth-estimator-dropdown">
                      <div className="row mb-2 align-items-center">
                        <label
                          className="col-md-4 pl-0 mb-0"
                          htmlFor="closeRateWithExistingCloseRate"
                        >
                          Existing Close Rate
                        </label>
                        <div className="col-md-8 pl-0 pr-0">
                          <input
                            type="text"
                            value={figures.closeRateWithExistingCloseRate}
                            id="closeRateWithExistingCloseRate"
                            name="closeRateWithExistingCloseRate"
                            disabled={true}
                            className="rounded p-2 border d-block w-100"
                          />
                        </div>
                      </div>

                      <div className="row mb-2 align-items-center">
                        <label
                          className="col-md-4 pl-0 mb-0"
                          htmlFor="closeRateWithNewLeadsPerYear"
                        >
                          New Leads/Year
                        </label>
                        <div className="col-md-8 pl-0 pr-0">
                          <input
                            type="text"
                            value={figures.closeRateWithNewLeadsPerYear}
                            id="closeRateWithNewLeadsPerYear"
                            name="closeRateWithNewLeadsPerYear"
                            disabled={true}
                            className="rounded p-2 border d-block w-100"
                          />
                        </div>
                      </div>
                      <div className="row mb-2 align-items-center">
                        <label
                          className="col-md-4 pl-0 mb-0"
                          htmlFor="closeRateWithNewCloseRate"
                        >
                          New Close Rate
                        </label>
                        <div className="col-md-8 pl-0 pr-0">
                          <input
                            type="text"
                            value={figures.closeRateWithNewCloseRate}
                            id="closeRateWithNewCloseRate"
                            name="closeRateWithNewCloseRate"
                            disabled={true}
                            className="rounded p-2 border d-block w-100"
                          />
                        </div>
                      </div>

                      <div className="row mb-2 align-items-center">
                        <label
                          className="col-md-4 pl-0 mb-0"
                          htmlFor="closeRateWithNumJobsIncreasedPerYear"
                        >
                          # Jobs Increased/Year
                        </label>
                        <div className="col-md-8 pl-0 pr-0">
                          <input
                            type="text"
                            value={figures.closeRateWithNumJobsIncreasedPerYear}
                            id="closeRateWithNumJobsIncreasedPerYear"
                            name="closeRateWithNumJobsIncreasedPerYear"
                            disabled={true}
                            className="rounded p-2 border d-block w-100"
                          />
                        </div>
                      </div>
                      <div className="row mb-0 align-items-center">
                        <label
                          className="col-md-4 pl-0 mb-0 font-weight-bold"
                          htmlFor="closeRateWithPotentialRevenueLossGain"
                        >
                          Potential Revenue (
                          <span className="text-red">Loss</span>/
                          <span className="text-green">Gain</span>)
                        </label>
                        <div
                          className={`col-md-8 pl-0 pr-0 currency-input ${getColorClassTotal(
                            "closeRateWithPotentialRevenueLossGain"
                          )} ${getTransparencyClass()}`}
                        >
                          <input
                            type="text"
                            value={
                              figures.closeRateWithPotentialRevenueLossGain
                            }
                            id="closeRateWithPotentialRevenueLossGain"
                            name="closeRateWithPotentialRevenueLossGain"
                            disabled={true}
                            className={`rounded p-2 pl-3 border d-block w-100 font-weight-bold ${getColorClassTotal(
                              "closeRateWithPotentialRevenueLossGain"
                            )} ${getTransparencyClass()}`}
                          />
                        </div>
                      </div>
                    </fieldset>
                  )}
                </div>
              </div>
            )}
            {activeTab === PROJECT_SIZE && (
              <div className="growth-estimator-tab-content">
                <h3 className="text-uppercase mb-2 text-md">
                  Project Size Growth
                </h3>
                <fieldset className="border rounded py-4 px-5">
                  <div className="row mb-2 align-items-center">
                    <label
                      className="col-md-4 pl-0 mb-0"
                      htmlFor="projectSizeWithoutCurrentAverageProjectSize"
                    >
                      Current Average Project Size
                    </label>
                    <div className="col-md-8 pl-0 pr-0 currency-input">
                      <input
                        type="text"
                        value={
                          figures.projectSizeWithoutCurrentAverageProjectSize
                        }
                        id="projectSizeWithoutCurrentAverageProjectSize"
                        name="projectSizeWithoutCurrentAverageProjectSize"
                        disabled={true}
                        className="rounded p-2 pl-3 border d-block w-100"
                      />
                    </div>
                  </div>
                  <div className="row mb-2 align-items-center">
                    <label
                      className="col-md-4 pl-0 mb-0 "
                      htmlFor="projectSizeWithoutPercentGrowth"
                    >
                      % Growth
                    </label>
                    <div className="col-md-8 pl-0 pr-0">
                      <input
                        type="text"
                        value={figures.projectSizeWithoutPercentGrowth}
                        id="projectSizeWithoutPercentGrowth"
                        name="projectSizeWithoutPercentGrowth"
                        onChange={handleInputChange}
                        className="rounded p-2 border d-block w-100"
                        data-percentage="true"
                        onBlur={addPercentSign}
                        onFocus={removePercentSign}
                        inputMode="decimal"
                      />
                    </div>
                  </div>

                  <div className="row mb-2 align-items-center">
                    <label
                      className="col-md-4 pl-0 mb-0"
                      htmlFor="projectSizeWithoutNewAverageFinancedTicket"
                    >
                      New Average Financed Ticket
                    </label>
                    <div className="col-md-8 pl-0 pr-0 currency-input">
                      <input
                        type="text"
                        value={
                          figures.projectSizeWithoutNewAverageFinancedTicket
                        }
                        id="projectSizeWithoutNewAverageFinancedTicket"
                        name="projectSizeWithoutNewAverageFinancedTicket"
                        disabled={true}
                        className="rounded p-2 pl-3 border d-block w-100"
                      />
                    </div>
                  </div>

                  <div className="row mb-2 align-items-center">
                    <label
                      className="col-md-4 pl-0 mb-0"
                      htmlFor="projectSizeWithoutPercentTotalProjectsFinanced"
                    >
                      % of Total Projects Financed
                    </label>
                    <div className="col-md-8 pl-0 pr-0">
                      <input
                        type="text"
                        value={
                          figures.projectSizeWithoutPercentTotalProjectsFinanced
                        }
                        id="projectSizeWithoutPercentTotalProjectsFinanced"
                        name="projectSizeWithoutPercentTotalProjectsFinanced"
                        onChange={handleInputChange}
                        data-percentage="true"
                        onBlur={addPercentSign}
                        onFocus={removePercentSign}
                        className="rounded p-2 border d-block w-100"
                      />
                    </div>
                  </div>
                  <div className="row mb-0 align-items-center">
                    <label
                      className="col-md-4 pl-0 mb-0 font-weight-bold"
                      htmlFor="projectSizeWithoutPotentialRevenueLossGain"
                    >
                      Potential Revenue (<span className="text-red">Loss</span>/
                      <span className="text-green">Gain</span>)
                    </label>
                    <div
                      className={`col-md-8 pl-0 pr-0 currency-input ${getColorClassTotal(
                        "projectSizeWithoutPotentialRevenueLossGain"
                      )}`}
                    >
                      <input
                        type="text"
                        value={
                          figures.projectSizeWithoutPotentialRevenueLossGain
                        }
                        id="projectSizeWithoutPotentialRevenueLossGain"
                        name="projectSizeWithoutPotentialRevenueLossGain"
                        disabled={true}
                        className={`rounded p-2 pl-3 border d-block w-100 font-weight-bold ${getColorClassTotal(
                          "projectSizeWithoutPotentialRevenueLossGain"
                        )}`}
                      />
                    </div>
                  </div>
                </fieldset>

                <div className="mt-5">
                  <button
                    type="button"
                    id={PROJECT_SIZE_DROPDOWN}
                    onClick={toggleDropdown}
                    className="text-uppercase mb-2 text-md d-block border-0 bg-white"
                  >
                    <ToggleIcon dropdown={PROJECT_SIZE_DROPDOWN} />
                    Project Size Growth{" "}
                    <span className="text-green no-click">with</span> Lead
                    and/or Close Rate Increase
                  </button>
                  {isActiveDropdown(PROJECT_SIZE_DROPDOWN) && (
                    <fieldset className="border rounded py-4 px-5 growth-estimator-dropdown">
                      <div className="row mb-2 align-items-center">
                        <label
                          className="col-md-4 pl-0 mb-0"
                          htmlFor="projectSizeWithCurrentAverageProjectSize"
                        >
                          Current Average Project Size
                        </label>
                        <div className="col-md-8 pl-0 pr-0 currency-input">
                          <input
                            type="text"
                            value={
                              figures.projectSizeWithCurrentAverageProjectSize
                            }
                            id="projectSizeWithCurrentAverageProjectSize"
                            name="projectSizeWithCurrentAverageProjectSize"
                            disabled={true}
                            className="rounded p-2 pl-3 border d-block w-100"
                          />
                        </div>
                      </div>
                      <div className="row mb-2 align-items-center">
                        <label
                          className="col-md-4 pl-0 mb-0 "
                          htmlFor="projectSizeWithPercentGrowth"
                        >
                          % Growth
                        </label>
                        <div className="col-md-8 pl-0 pr-0">
                          <input
                            type="text"
                            value={figures.projectSizeWithPercentGrowth}
                            id="projectSizeWithPercentGrowth"
                            name="projectSizeWithPercentGrowth"
                            disabled={true}
                            className="rounded p-2 border d-block w-100"
                          />
                        </div>
                      </div>
                      <div className="row mb-2 align-items-center">
                        <label
                          className="col-md-4 pl-0 mb-0"
                          htmlFor="projectSizeWithNewAverageFinancedTicket"
                        >
                          New Average Financed Ticket
                        </label>
                        <div className="col-md-8 pl-0 pr-0 currency-input">
                          <input
                            type="text"
                            value={
                              figures.projectSizeWithNewAverageFinancedTicket
                            }
                            id="projectSizeWithNewAverageFinancedTicket"
                            name="projectSizeWithNewAverageFinancedTicket"
                            disabled={true}
                            className="rounded p-2 pl-3 border d-block w-100"
                          />
                        </div>
                      </div>

                      <div className="row mb-2 align-items-center">
                        <label
                          className="col-md-4 pl-0 mb-0"
                          htmlFor="projectSizeWithPercentTotalProjectsFinanced"
                        >
                          % of Total Projects Financed
                        </label>
                        <div className="col-md-8 pl-0 pr-0">
                          <input
                            type="text"
                            value={
                              figures.projectSizeWithPercentTotalProjectsFinanced
                            }
                            id="projectSizeWithPercentTotalProjectsFinanced"
                            name="projectSizeWithPercentTotalProjectsFinanced"
                            disabled={true}
                            className="rounded p-2 border d-block w-100"
                          />
                        </div>
                      </div>
                      <div className="row mb-0 align-items-center">
                        <label
                          className="col-md-4 pl-0 mb-0 font-weight-bold"
                          htmlFor="projectSizeWithPotentialRevenueLossGain"
                        >
                          Potential Revenue (
                          <span className="text-red">Loss</span>/
                          <span className="text-green">Gain</span>)
                        </label>
                        <div
                          className={`col-md-8 pl-0 pr-0 currency-input ${getColorClassTotal(
                            "projectSizeWithPotentialRevenueLossGain"
                          )}`}
                        >
                          <input
                            type="text"
                            value={
                              figures.projectSizeWithPotentialRevenueLossGain
                            }
                            id="projectSizeWithPotentialRevenueLossGain"
                            name="projectSizeWithPotentialRevenueLossGain"
                            disabled={true}
                            className={`rounded p-2 pl-3 border d-block w-100 font-weight-bold ${getColorClassTotal(
                              "projectSizeWithPotentialRevenueLossGain"
                            )}`}
                          />
                        </div>
                      </div>
                    </fieldset>
                  )}
                </div>
              </div>
            )}
            {activeTab === GROWTH_SUMMARY && (
              <div className="growth-estimator-tab-content">
                <fieldset className="border rounded py-4 px-5">
                  <div className="row mb-2 align-items-center">
                    <label
                      className="col-md-4 pl-0 mb-0"
                      htmlFor="growthSummaryNewLeads"
                    >
                      New Leads
                    </label>
                    <div className="col-md-8 pl-0 pr-0">
                      <input
                        type="text"
                        value={figures.growthSummaryNewLeads}
                        id="growthSummaryNewLeads"
                        name="growthSummaryNewLeads"
                        disabled={true}
                        className="rounded p-2 border d-block w-100"
                      />
                    </div>
                  </div>
                  <div className="row mb-2 align-items-center">
                    <label
                      className="col-md-4 pl-0 mb-0"
                      htmlFor="growthSummaryNewCloseRate"
                    >
                      New Close Rate
                    </label>
                    <div className="col-md-8 pl-0 pr-0">
                      <input
                        type="text"
                        value={figures.growthSummaryNewCloseRate}
                        id="growthSummaryNewCloseRate"
                        name="growthSummaryNewCloseRate"
                        disabled={true}
                        className="rounded p-2 border d-block w-100"
                      />
                    </div>
                  </div>
                  <div className="row mb-0 align-items-center">
                    <label
                      className="col-md-4 pl-0 mb-0"
                      htmlFor="growthSummaryNewProjectSize"
                    >
                      New Project Size
                    </label>
                    <div className="col-md-8 pl-0 pr-0 currency-input">
                      <input
                        type="text"
                        value={figures.growthSummaryNewProjectSize}
                        id="growthSummaryNewProjectSize"
                        name="growthSummaryNewProjectSize"
                        disabled={true}
                        className="rounded p-2 pl-3 border d-block w-100"
                      />
                    </div>
                  </div>
                </fieldset>

                <fieldset className="border rounded py-4 px-5 mt-5">
                  <div className="row mb-2 align-items-center">
                    <label
                      className="col-md-4 pl-0 mb-0"
                      htmlFor="growthSummaryCurrentAnnualResidentialSales"
                    >
                      Current Annual Residential Sales
                    </label>
                    <div className="col-md-8 pl-0 pr-0 currency-input">
                      <input
                        type="text"
                        value={
                          figures.growthSummaryCurrentAnnualResidentialSales
                        }
                        id="growthSummaryCurrentAnnualResidentialSales"
                        name="growthSummaryCurrentAnnualResidentialSales"
                        disabled={true}
                        className="rounded p-2 pl-3 border d-block w-100"
                      />
                    </div>
                  </div>
                  <div className="row mb-2 align-items-center">
                    <label
                      className="col-md-4 pl-0 mb-0"
                      htmlFor="growthSummaryNewAnnualSalesEstimate"
                    >
                      New Annual Sales Estimate
                    </label>
                    <div className="col-md-8 pl-0 pr-0 currency-input">
                      <input
                        type="text"
                        value={figures.growthSummaryNewAnnualSalesEstimate}
                        id="growthSummaryNewAnnualSalesEstimate"
                        name="growthSummaryNewAnnualSalesEstimate"
                        disabled={true}
                        className="rounded p-2 pl-3 border d-block w-100"
                      />
                    </div>
                  </div>
                  <div className="row mb-0 align-items-center">
                    <label
                      className="col-md-4 pl-0 mb-0 font-weight-bold"
                      htmlFor="growthSummaryTotalRevenueLossGain"
                    >
                      Total Revenue (<span className="text-red">Loss</span>/
                      <span className="text-green">Gain</span>)
                    </label>
                    <div
                      className={`col-md-8 pl-0 pr-0 currency-input ${getColorClassTotal(
                        "growthSummaryTotalRevenueLossGain"
                      )}`}
                    >
                      <input
                        type="text"
                        value={figures.growthSummaryTotalRevenueLossGain}
                        id="growthSummaryTotalRevenueLossGain"
                        name="growthSummaryTotalRevenueLossGain"
                        disabled={true}
                        className={`rounded p-2 pl-3 border d-block w-100 font-weight-bold ${getColorClassTotal(
                          "growthSummaryTotalRevenueLossGain"
                        )}`}
                      />
                    </div>
                  </div>
                </fieldset>
              </div>
            )}
            {activeTab === RETURN_ON_INVESTMENT && (
              <div className="growth-estimator-tab-content">
                <fieldset className="border rounded py-4 px-5">
                  <div className="row mb-2 align-items-center">
                    <label
                      className="col-md-4 pl-0 mb-0"
                      htmlFor="returnOnInvestmentFee1"
                    >
                      Fee 1
                    </label>
                    <div className="col-md-8 pl-0 pr-0">
                      <input
                        type="text"
                        value={figures.returnOnInvestmentFee1}
                        id="returnOnInvestmentFee1"
                        name="returnOnInvestmentFee1"
                        className="rounded p-2 border d-block w-100"
                        onChange={handleInputChange}
                        data-percentage="true"
                        onBlur={addPercentSign}
                        onFocus={removePercentSign}
                        inputMode="decimal"
                      />
                    </div>
                  </div>
                  <div className="row mb-2 align-items-center">
                    <label
                      className="col-md-4 pl-0 mb-0"
                      htmlFor="returnOnInvestmentFee2"
                    >
                      Fee 2
                    </label>
                    <div className="col-md-8 pl-0 pr-0">
                      <input
                        type="text"
                        value={figures.returnOnInvestmentFee2}
                        id="returnOnInvestmentFee2"
                        name="returnOnInvestmentFee2"
                        className="rounded p-2 border d-block w-100"
                        onChange={handleInputChange}
                        data-percentage="true"
                        onBlur={addPercentSign}
                        onFocus={removePercentSign}
                        inputMode="decimal"
                      />
                    </div>
                  </div>

                  <div className="row mb-2 align-items-center">
                    <label
                      className="col-md-4 pl-0 mb-0"
                      htmlFor="returnOnInvestmentFee3"
                    >
                      Fee 3
                    </label>
                    <div className="col-md-8 pl-0 pr-0">
                      <input
                        type="text"
                        value={figures.returnOnInvestmentFee3}
                        id="returnOnInvestmentFee3"
                        name="returnOnInvestmentFee3"
                        className="rounded p-2 border d-block w-100"
                        onChange={handleInputChange}
                        data-percentage="true"
                        onBlur={addPercentSign}
                        onFocus={removePercentSign}
                        inputMode="decimal"
                      />
                    </div>
                  </div>
                  <div className="row mb-0 align-items-center">
                    <label
                      className="col-md-4 pl-0 mb-0"
                      htmlFor="returnOnInvestmentAverageContractorFee"
                    >
                      Average Contractor Fee
                    </label>
                    <div className="col-md-8 pl-0 pr-0">
                      <input
                        type="text"
                        value={figures.returnOnInvestmentAverageContractorFee}
                        id="returnOnInvestmentAverageContractorFee"
                        name="returnOnInvestmentAverageContractorFee"
                        className="rounded p-2 border d-block w-100"
                        disabled={true}
                      />
                    </div>
                  </div>
                </fieldset>

                <fieldset className="border rounded py-4 px-5 mt-5">
                  <div className="row mb-2 align-items-center">
                    <label
                      className="col-md-4 pl-0 mb-0"
                      htmlFor="returnOnInvestmentTotalFees"
                    >
                      Total Fees
                    </label>
                    <div className="col-md-8 pl-0 pr-0 currency-input text-red">
                      <input
                        type="text"
                        value={figures.returnOnInvestmentTotalFees}
                        id="returnOnInvestmentTotalFees"
                        name="returnOnInvestmentTotalFees"
                        disabled={true}
                        className="rounded p-2 pl-3 border d-block w-100 text-red"
                      />
                    </div>
                  </div>

                  <div className="row mb-2 align-items-center">
                    <label
                      className="col-md-4 pl-0 mb-0"
                      htmlFor="returnOnInvestmentNewAnnualSalesEstimateFees"
                    >
                      New Annual Sales Estimate - Fees
                    </label>
                    <div className="col-md-8 pl-0 pr-0 currency-input">
                      <input
                        type="text"
                        value={
                          figures.returnOnInvestmentNewAnnualSalesEstimateFees
                        }
                        id="returnOnInvestmentNewAnnualSalesEstimateFees"
                        name="returnOnInvestmentNewAnnualSalesEstimateFees"
                        disabled={true}
                        className="rounded p-2 pl-3 border d-block w-100"
                      />
                    </div>
                  </div>

                  <div className="row mb-0 align-items-center">
                    <label
                      className="col-md-4 pl-0 mb-0 font-weight-bold"
                      htmlFor="returnOnInvestmentTotalRevenueAfterFees"
                    >
                      Total Revenue (<span className="text-red">Loss</span>/
                      <span className="text-green">Gain</span>) After Fees
                    </label>
                    <div
                      className={`col-md-8 pl-0 pr-0 currency-input ${getColorClassTotal(
                        "returnOnInvestmentTotalRevenueAfterFees"
                      )}`}
                    >
                      <input
                        type="text"
                        value={figures.returnOnInvestmentTotalRevenueAfterFees}
                        id="returnOnInvestmentTotalRevenueAfterFees"
                        name="returnOnInvestmentTotalRevenueAfterFees"
                        disabled={true}
                        className={`rounded p-2 pl-3 border d-block w-100 font-weight-bold ${getColorClassTotal(
                          "returnOnInvestmentTotalRevenueAfterFees"
                        )}`}
                      />
                    </div>
                  </div>
                </fieldset>

                <div className="mt-5">
                  <button
                    type="button"
                    onClick={toggleDropdown}
                    id={BEST_PRACTICES_DROPDOWN}
                    className="text-uppercase mb-2 text-md d-block border-0 bg-white"
                  >
                    <ToggleIcon dropdown={BEST_PRACTICES_DROPDOWN} />
                    Best Practices
                  </button>
                  {isActiveDropdown(BEST_PRACTICES_DROPDOWN) && (
                    <ol className="growth-estimator-dropdown growth-estimator-best-practices">
                      <li>
                        <div className="d-flex align-items-center">
                          <span className="counter">1</span>
                        </div>
                        <span>
                          <strong>Select a choice</strong> of Same-As-Cash and
                          Reduced Interest Loans to offer.
                        </span>
                      </li>
                      <li>
                        <div className="d-flex align-items-center">
                          <span className="counter">2</span>
                        </div>
                        <span>
                          <strong>
                            We recommend working with your accounting department
                          </strong>{" "}
                          to build the cost of payment options into your
                          overhead.
                        </span>
                      </li>
                      <li>
                        <div className="d-flex align-items-center">
                          <span className="counter">3</span>
                        </div>
                        <span>
                          <strong>Train your team</strong> so they're
                          comfortable and confident talking about payment
                          options with customers.
                        </span>
                      </li>
                      <li>
                        <div className="d-flex align-items-center">
                          <span className="counter">4</span>
                        </div>
                        <span>
                          <strong>Advertise and promote financing</strong> to
                          every customer and build it into your sales process.
                        </span>
                      </li>
                    </ol>
                  )}
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

export default BusinessGrowthEstimator

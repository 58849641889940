import React from 'react'
import useLiveControl from '../controlled/useLiveControl'
import fdicLogo from '../images/fdic-logo.svg'
import logo from '../images/enerbank-regions-logo.svg'

const StrippedLayout = ({ children }) => {
  const { baseUrl } = useLiveControl()
  const currentYear = new Date().getFullYear()

  return (
    <>
      <header>
        <nav className='stripped-header py-3'>
          <div className='container text-center' style={{maxWidth: "420px"}}>
            <a href={`${baseUrl}/`}>
              <img
                src={logo}
                alt='Regions | EnerBank USA'
                title='Regions | EnerBank USA'
              />
            </a>
          </div>
        </nav>
      </header>
      <main>{children}</main>
      <footer className='bg-blue py-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8 text-white d-lg-flex align-items-center footer-bottom text-center text-lg-left'>
              <img
                src={fdicLogo}
                alt='Member FDIC Logo'
                className='img-fluid mb-3 mb-sm-0 mr-sm-3'
              />
              <p className='pt-2 mt-3 mt-lg-0'>
                &copy;<span>{currentYear}</span> Regions Bank. | Regions, the
                Regions logo, and EnerBank are registered trademarks of Regions
                Bank. The LifeGreen color is a trademark of Regions Bank. This
                site is directed at, and made available to, persons in the
                continental U.S., Alaska and Hawaii only. All rights reserved.
              </p>
            </div>
            <div
              className='col-lg-4 mt-2 pt-1 mt-lg-0 text-center text-lg-right'
              style={{ lineHeight: '1.15' }}
            >
              <small>
                Credit and loans provided by Regions Bank d/b/a EnerBank USA,
                Member FDIC
              </small>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default StrippedLayout

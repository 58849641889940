import React, { useEffect, useState }  from "react"
import { Modal } from "react-bootstrap"
import close_icon from "../images/closed_icon.svg"

const GrowthEstimatorLoginPopup = props => {

  const { open, onClose } = props

  const handleClose = () => {
    onClose()
  }

  const [password, setPassword] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    setPageLoaded(true);
}, []);

useEffect(() => {
    if (pageLoaded) {

      checkPw();
        // console.log(isVerified);
    }
}, [pageLoaded]);

const saveData = () => {
  //saving username to sesstion storage
  sessionStorage.setItem("pass", password);

  setIsSaved(true);
  setTimeout(() => {
    setIsSaved(false);
  }, 2000);

  checkPw();

};

const getSessionData = () => {
  //fetching username from sesstion storage
  return sessionStorage.getItem("pass");
};


  const checkPw = () => {
    // gets the current input value
    const answer = getSessionData();

   // console.log(isVerified);
  
    if (answer === "training2023") { 
      setIsVerified(true);
      document.getElementById("growth-calculator").classList.remove("unauthorized");
    } else {
     // alert("Sorry, that's not it");
      setIsVerified(false);
    }
  };
  
  // if (props.popData.length !== 0) {
  return (
    <>
    {!isVerified ?  
    <Modal
      show={open}
      animation={true}
      centered={true}
      size="medium"
      id="growthEstimator"
    >
      <div className="heading_bg">
        <button
          type="button"
          className="close text-white"
          data-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        >
          <img src={close_icon} width="25" alt="close icon" />
        </button>
        <h3 className="h2 font-weight-bold">Enter Password to View Content</h3>
      </div>
      <div className="modal_content">
        <div className="p-5 py-sm-5">
          <div className="row">
            <div className="col-lg-12">
              <form id="growth-form" onSubmit={checkPw}>
                <div className="input">
                  <input 
                  type="password" 
                  id="password" 
                  name="password"
                  value={password}
                  onChange={({ target }) => {
                    setPassword(target.value);
                  }}
                  />
                </div>
                  <button onClick={saveData} className="hs-button primary large">{isSaved ? 'Saved!' : 'Submit'}</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      
    </Modal>
    : (
      null
    )
    }
    </>
  )
  // } else {
  //   return (
  //     <div className="modal fade show" style={{ display: props.visiblity }}>
  //       &nbsp;
  //     </div>
  //   )
  // }
}
export default GrowthEstimatorLoginPopup

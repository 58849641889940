import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import BusinessGrowthEstimator from "../components/businessGrowthEstimator"
import StrippedLayout from "../components/strippedLayout"
import Seo from "../components/seo"
import GrowthEstimatorLoginPopup from "./../components/growthEstimatorLoginPopup"

const GrowthEstimatorPage = () => {

 // const { isStaging, runningChecks } = useLiveControl()

 // if (runningChecks || !isStaging) return <LiveControlSpinner />

 // const [isVerified, setIsVerified] = useState(false);
 //const [isVerified, setIsVerified] = useState();
  const [open, setOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

useEffect(() => {
    setIsLoaded(true);
}, []);

useEffect(() => {
    if (isLoaded) {

        setOpen(true);
        // console.log(isVerified);
    }
}, [isLoaded]);


  

  // const checkPw = () => {
  //   // gets the current input value
  //   const answer = document.getElementById("password").value;
  
  //   if (answer === "training2023") { 
  //     sessionStorage.setItem('answer', answer);
  //     setIsVerified(true);
  //   } else {
  //     alert("Sorry, that's not it");
  //   }
  // };

  return (
    <>
    
    <StrippedLayout>
      <Seo title="Business Growth Estimator" />
      <Helmet>
        <meta name="robots" content="noindex" />
        {/* <body className={ `growth-estimator-page ${open ? "unauthorized" : ( null )}` } /> */}
      </Helmet>
      <div id="growth-calculator" className="growth-estimator-page unauthorized" >
      <section className="section-gap bg-light-gray">
        <div className="container">
          <h1 className="text-center mb-5 mt-0 font-32">
            Grow Your Business with Payment Options
          </h1>
          <ul className="row text-center mt-5 pt-2">
            <li className="col-md-4">
              <div className="bg-regions-life-green mb-3 box-circle growth-estimator-icon mx-auto">
                <img
                  src="https://gbdevpress.enerbank.com/wp-content/uploads/2022/05/PaymentOptions_Increase_Leads_transparent.svg"
                  alt="advertising payment options"
                />
              </div>
              <p className="font-weight-bold my-4 est-text-large">
                Increase <br />
                Leads
              </p>
              <p className="width-200 mx-auto">
                Advertising payment options can increase leads by 50%.*
              </p>
            </li>
            <li className="col-md-4">
              <div className="bg-regions-life-green mb-3 box-circle growth-estimator-icon mx-auto">
                <img
                  src="https://gbdevpress.enerbank.com/wp-content/uploads/2022/05/PaymentOptions_Boost_Close_Rate-1.svg"
                  alt="boosting close rate"
                />
              </div>
              <p className="font-weight-bold my-4 est-text-large">
                Boost <br /> Close Rate
              </p>
              <p className="width-200 mx-auto">
                Consistently offering a choice of attractive loan options has
                been shown to increase close rate from 25% to 44%.*
              </p>
            </li>
            <li className="col-md-4">
              <div className="bg-regions-life-green mb-3 box-circle growth-estimator-icon mx-auto">
                <img
                  src="https://gbdevpress.enerbank.com/wp-content/uploads/2022/05/PaymentOptions_Grow_Average_Project_Size-1.svg"
                  alt="growing project size"
                />
              </div>
              <p className="font-weight-bold my-4 est-text-large">
                Grow Average <br /> Project Size
              </p>
              <p className="width-210 mx-auto">
                Offering flexible payment options can grow average project size
                by 44%.*
              </p>
            </li>
          </ul>
        </div>
      </section>
      <section className="bg-white section-gap">
        <h2 className="text-center">Business Growth Estimator</h2>
        <p className="text-center mt-4 mb-5 width-600 mx-auto">
          An estimate tool designed to calculate potential business growth when
          following best practices of offering payment options to every
          customer.**
        </p>
        <BusinessGrowthEstimator />
      </section>
      <section className="py-5 bg-white">
        <div className="container-fluid max-1600">
          <small className="d-block">
            *Based on the Brickyard Study commissioned by Regions | EnerBank USA
            <sup>&reg;</sup>, 2018
          </small>

          <small className="d-block mt-4">
            **On average, our contractors have seen significant increase in
            inbound leads, close rates, average job sizes and annual revenue by
            offering payment options to every customer. This calculator is
            provided for reference only. This is not a guarantee of your actual
            results and the results are calculated using rounded numbers. Actual
            performance will vary based on individual circumstances. The results
            of the calculator are hypothetical and are for illustrative purposes
            only.
          </small>
        </div>
        <GrowthEstimatorLoginPopup open={open} onClose={() => setOpen(false)} />         
      </section>
      </div>
    </StrippedLayout>
    </>
  )
}

export default GrowthEstimatorPage
